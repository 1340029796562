var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        size: "extra-large",
        title: _vm.translations.sendEmailToCustomer.text,
        "dialog-classes": "pop-up-send-email-title",
      },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "pop-up-send-email" },
        [
          _c("div", { staticClass: "pop-up-send-email__subtitle" }, [
            _vm._v(_vm._s(_vm.customerWouldPreferLanguage)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-send-email__content" }, [
            _c("div", { staticClass: "pop-up-send-email__settings" }, [
              _c("div", { staticClass: "pop-up-send-email__settings--name" }, [
                _vm._v(_vm._s(_vm.senderName)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pop-up-send-email__types" },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.$options.types,
                      translates: _vm.typesTranslates,
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pop-up-send-email__upload-pdf",
                  on: {
                    click: function ($event) {
                      return _vm.upFile()
                    },
                  },
                },
                [
                  _c("base-icon", {
                    staticClass: "pop-up-send-email__upload-pdf--clip",
                    attrs: { "icon-name": "Clip" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: [
                        _vm.isFileError ? "pop-up-send-email__text--error" : "",
                        _vm.file ? "has-file" : "no-file",
                        "pop-up-send-email__text",
                      ],
                    },
                    [
                      _vm.file === null
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.isFileError
                                  ? "pop-up-send-email__text--error"
                                  : "",
                                "pop-up-send-email__text--title",
                              ],
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.translations.uploadPdfFile.text) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.file === null
                        ? _c(
                            "div",
                            {
                              class: [
                                _vm.isFileError
                                  ? "pop-up-send-email__text--error"
                                  : "",
                                "pop-up-send-email__text--subtitle",
                              ],
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.translations.filesSize.text) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "pop-up-send-email__text--name" },
                            [_vm._v(_vm._s(_vm.file.name))]
                          ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pop-up-send-email__msg" },
              [
                _vm.isPickupDetails
                  ? _c(
                      "div",
                      [
                        _c("google-autocomplete", {
                          attrs: {
                            error: _vm.$v.pickupAddress.$error,
                            placeholder:
                              _vm.translations.enterPickupAddress.text,
                          },
                          on: { "update:point": _vm.updateAddress },
                          model: {
                            value: _vm.$v.pickupAddress.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.pickupAddress, "$model", $$v)
                            },
                            expression: "$v.pickupAddress.$model",
                          },
                        }),
                        _vm._v(" "),
                        _vm.$v.pickupAddress.$error
                          ? _c("p", { staticClass: "validation-error" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.translations.pickupAddressError.text
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("VTextarea", {
                  class: !_vm.isPickupDetails
                    ? "pop-up-send-email__msg--regular-message"
                    : "",
                  attrs: {
                    placeholder:
                      _vm.translations.customerMessagePlaceholder.text,
                    error: _vm.$v.msg.$error,
                    "error-message": _vm.translations.customerMessageError.text,
                    "max-length": 400,
                    rows: "5",
                  },
                  model: {
                    value: _vm.$v.msg.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.msg, "$model", $$v)
                    },
                    expression: "$v.msg.$model",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("v-btn", {
            staticClass: "pop-up-send-email__send",
            attrs: {
              base: "",
              name: _vm.translations.customerMessageSend.text,
              loading: _vm.loading,
              "loading-name": "Sending",
            },
            on: {
              click: function ($event) {
                return _vm.sendEmail()
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }