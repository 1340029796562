export function stringStartsWith(str, strings) {
  if (!str) return false
  return strings.some((substr) => str.startsWith(substr))
}

const locales = {
  en: 'en-US', // English (United States, Canada, UK, Australia, New Zealand, Ireland, South Africa)
  hi: 'hi-IN', // Hindi (India)
  ur: 'ur-PK', // Urdu (Pakistan)
  bn: 'bn-BD', // Bengali (Bangladesh)
  es: 'es-ES', // Spanish (Spain)
  'es-MX': 'es-MX', // Spanish (Mexico)
  fil: 'fil-PH', // Filipino (Philippines)
  de: 'de-DE', // German (Germany)
}

export function formatCurrency(value, lang, currencyDisplay = 'symbol') {
  const locale = locales[lang] || 'en-US'
  const options = {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay, // По умолчанию 'symbol' или 'code'
    useGrouping: true,
  }

  // Если нужно полностью убрать символ и код валюты
  if (currencyDisplay === 'none') {
    options.style = 'decimal' // Переключаемся на децимальное отображение
    options.minimumFractionDigits = 2 // Минимальное количество дробных цифр
    options.maximumFractionDigits = 2 // Максимальное количество дробных цифр
    delete options.currency
    delete options.currencyDisplay
  }

  const formatter = new Intl.NumberFormat(locale, options)
  return formatter.format(value)
}

// Список стран с 12-часовым форматом времени
const twelveHourCountries = [
  { country: 'India', code: 'hi' },
  { country: 'United States', code: 'en' },
  { country: 'Pakistan', code: 'ur' },
  { country: 'Bangladesh', code: 'bn' },
  { country: 'Mexico', code: 'es-MX' },
  { country: 'Philippines', code: 'fil' },
  { country: 'United Kingdom', code: 'en' },
  { country: 'South Africa', code: 'en' },
  { country: 'Canada', code: 'en' },
  { country: 'Australia', code: 'en' },
  { country: 'New Zealand', code: 'en' },
  { country: 'Ireland', code: 'en' },
]

const twelveHourLangs = twelveHourCountries.map((country) => country.code)

const format12Hour = 'hh:mm a'
const format24Hour = 'HH:mm'

// Строковые форматы для различных языков
const formats = {
  en: format12Hour,
  'es-MX': format12Hour,
  hi: format12Hour,
  ur: format12Hour,
  bn: format12Hour,
  fil: format12Hour,
  es: format24Hour,
  de: format24Hour,
}

export function formatTime({ time, lang = 'en', scheduleType }) {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: twelveHourLangs.includes(lang),
  }
  const locale = locales[lang] || 'en-US'
  if (scheduleType === 'start_times') {
    // Обработка списка времени для "start_times"
    if (Array.isArray(time)) {
      return time.map((t) => new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${t}`))).join(', ')
    } else if (typeof time === 'string') {
      // Обработка одиночного значения времени
      return new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${time}`))
    }
  } else if (Array.isArray(time) && time.length === 2) {
    return time.map((t) => new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${t}`))).join(' - ')
  } else if (Array.isArray(time)) {
    return time.map((t) => new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${t}`))).join(', ')
  }
  // Обработка списка времени для "opening_hours" и "coordinated_start_times"
  else if (Array.isArray(time) && time.length % 2 === 0) {
    const formattedRanges = []
    for (let i = 0; i < time.length; i += 2) {
      const formattedTime1 = new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${time[i]}`))
      const formattedTime2 = new Intl.DateTimeFormat(locale, options).format(new Date(`1970-01-01T${time[i + 1]}`))
      formattedRanges.push(`${formattedTime1} - ${formattedTime2}`)
    }
    return formattedRanges.join(', ')
  }

  if (typeof time === 'string') {
    const date = new Date(`1970-01-01T${time}`)
    const result = date.toLocaleString(locale, options)
    return result
  }

  // Если time не передано или не соответствует ожидаемому формату, возвращаем строковый формат
  return formats[lang] || format12Hour
}
