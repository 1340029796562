export { default as ButtonMore } from '../../components/ButtonMore.vue'
export { default as CategoriesSelector } from '../../components/CategoriesSelector.vue'
export { default as ChangeLang } from '../../components/ChangeLang.vue'
export { default as ErrorBanner } from '../../components/ErrorBanner.vue'
export { default as GoogleAutocomplete } from '../../components/GoogleAutocomplete.vue'
export { default as Loader } from '../../components/Loader.vue'
export { default as ProductCard } from '../../components/ProductCard.vue'
export { default as TourCard } from '../../components/TourCard.vue'
export { default as VConfirmDialog } from '../../components/VConfirmDialog.vue'
export { default as VPagination } from '../../components/VPagination.vue'
export { default as VPopover } from '../../components/VPopover.vue'
export { default as PageHeader } from '../../components/page-header.vue'
export { default as PhotoHelp } from '../../components/photoHelp.vue'
export { default as ProductNav } from '../../components/product-nav.vue'
export { default as RegNav } from '../../components/reg-nav.vue'
export { default as AccountSetupAddressForm } from '../../components/AccountSetup/AddressForm.vue'
export { default as AccountSetupCategoriesFormTags } from '../../components/AccountSetup/CategoriesFormTags.vue'
export { default as AccountSetupCategoryForm } from '../../components/AccountSetup/CategoryForm.vue'
export { default as AccountSetupPersonalDetailsForm } from '../../components/AccountSetup/PersonalDetailsForm.vue'
export { default as AccountSetupYourExpertiseForm } from '../../components/AccountSetup/YourExpertiseForm.vue'
export { default as AccountSetupYourInformationForm } from '../../components/AccountSetup/YourInformationForm.vue'
export { default as AccountSetupYourProfilePictureForm } from '../../components/AccountSetup/YourProfilePictureForm.vue'
export { default as AttractionsCard } from '../../components/Attractions/Card.vue'
export { default as AttractionsCreateModal } from '../../components/Attractions/CreateModal.vue'
export { default as PayoutMethodsAddressForm } from '../../components/PayoutMethods/AddressForm.vue'
export { default as PayoutMethodsBankDetailsForm } from '../../components/PayoutMethods/BankDetailsForm.vue'
export { default as ProductBackLink } from '../../components/Product/BackLink.vue'
export { default as ProductInformationAboutTicket } from '../../components/Product/InformationAboutTicket.vue'
export { default as BookingAndTicketsInformationOnVoucherPage } from '../../components/bookingAndTickets/InformationOnVoucherPage.vue'
export { default as BookingAndTicketsTicketMobilePreview } from '../../components/bookingAndTickets/TicketMobilePreview.vue'
export { default as BookingAndTicketsTicketPaperPreview } from '../../components/bookingAndTickets/TicketPaperPreview.vue'
export { default as BookingProcessPage } from '../../components/bookingAndTickets/bookingProcessPage.vue'
export { default as BookingAndTicketsUtils } from '../../components/bookingAndTickets/utils.js'
export { default as CommonBaseIcon } from '../../components/common/BaseIcon.vue'
export { default as CommonBaseMap } from '../../components/common/BaseMap.vue'
export { default as CommonLocalLoader } from '../../components/common/LocalLoader.vue'
export { default as CommonSmallMap } from '../../components/common/SmallMap.vue'
export { default as CommonVDialog } from '../../components/common/VDialog.vue'
export { default as CommonVExpandableList } from '../../components/common/VExpandableList.vue'
export { default as CommonVExpandableText } from '../../components/common/VExpandableText.vue'
export { default as CommonVExpansionList } from '../../components/common/VExpansionList.vue'
export { default as CommonVExpansionText } from '../../components/common/VExpansionText.vue'
export { default as CommonVNotificationItem } from '../../components/common/VNotificationItem.vue'
export { default as CommonVNotificationsView } from '../../components/common/VNotificationsView.vue'
export { default as CommonVQuestionInfo } from '../../components/common/VQuestionInfo.vue'
export { default as CommonVTable } from '../../components/common/VTable.vue'
export { default as ControlsBtn } from '../../components/controls/Btn.vue'
export { default as ControlsCheckbox } from '../../components/controls/Checkbox.vue'
export { default as ControlsInputFields } from '../../components/controls/Input-fields.vue'
export { default as ControlsRadioButton } from '../../components/controls/RadioButton.vue'
export { default as ControlsSelection } from '../../components/controls/Selection.vue'
export { default as ControlsVBreadcrumbs } from '../../components/controls/VBreadcrumbs.vue'
export { default as ControlsVBtn } from '../../components/controls/VBtn.vue'
export { default as ControlsVButton } from '../../components/controls/VButton.vue'
export { default as ControlsVCheckbox } from '../../components/controls/VCheckbox.vue'
export { default as ControlsVField } from '../../components/controls/VField.vue'
export { default as ControlsVFileInput } from '../../components/controls/VFileInput.vue'
export { default as ControlsVInput } from '../../components/controls/VInput.vue'
export { default as ControlsVRadioButton } from '../../components/controls/VRadioButton.vue'
export { default as ControlsVRadioGroup } from '../../components/controls/VRadioGroup.vue'
export { default as ControlsVSelect } from '../../components/controls/VSelect.vue'
export { default as ControlsVSelectCountry } from '../../components/controls/VSelectCountry.vue'
export { default as ControlsVTextarea } from '../../components/controls/VTextarea.vue'
export { default as IconsArrowLeft } from '../../components/icons/ArrowLeft.vue'
export { default as IconsCheckmark } from '../../components/icons/Checkmark.vue'
export { default as IconsChevronDoubleDown } from '../../components/icons/ChevronDoubleDown.vue'
export { default as IconsClip } from '../../components/icons/Clip.vue'
export { default as IconsClose } from '../../components/icons/Close.vue'
export { default as IconsCovid } from '../../components/icons/Covid.vue'
export { default as IconsDelete } from '../../components/icons/Delete.vue'
export { default as IconsDotsVerical } from '../../components/icons/DotsVerical.vue'
export { default as IconsDownload } from '../../components/icons/Download.vue'
export { default as IconsEdit } from '../../components/icons/Edit.vue'
export { default as IconsHomeEdit } from '../../components/icons/HomeEdit.vue'
export { default as IconsHomePay } from '../../components/icons/HomePay.vue'
export { default as IconsInfo } from '../../components/icons/Info.vue'
export { default as IconsLearnMoreConnection } from '../../components/icons/LearnMoreConnection.vue'
export { default as IconsRating1Star } from '../../components/icons/Rating1Star.vue'
export { default as IconsRating2Star } from '../../components/icons/Rating2Star.vue'
export { default as IconsRating3Star } from '../../components/icons/Rating3Star.vue'
export { default as IconsRating4Star } from '../../components/icons/Rating4Star.vue'
export { default as IconsRating5Star } from '../../components/icons/Rating5Star.vue'
export { default as IconsTourCancellation } from '../../components/icons/TourCancellation.vue'
export { default as IconsTourCustomize } from '../../components/icons/TourCustomize.vue'
export { default as IconsTourDuration } from '../../components/icons/TourDuration.vue'
export { default as IconsTourGuide } from '../../components/icons/TourGuide.vue'
export { default as IconsTourPrivate } from '../../components/icons/TourPrivate.vue'
export { default as IconsTourSkipLine } from '../../components/icons/TourSkipLine.vue'
export { default as IconsTourSmall } from '../../components/icons/TourSmall.vue'
export { default as IconsTourTransfer } from '../../components/icons/TourTransfer.vue'
export { default as IconsTourVouchers } from '../../components/icons/TourVouchers.vue'
export { default as IconsTrash } from '../../components/icons/Trash.vue'
export { default as IconsLocationPhoto } from '../../components/icons/locationPhoto.vue'
export { default as NavBarsAccountNav } from '../../components/navBars/AccountNav.vue'
export { default as PopUpAvailabilityConfirm } from '../../components/pop-up/AvailabilityConfirm.vue'
export { default as PopUpBigMap } from '../../components/pop-up/BigMap.vue'
export { default as PopUpBookingAcknowledgeSave } from '../../components/pop-up/BookingAcknowledgeSave.vue'
export { default as PopUpBookingAmendedConfirm } from '../../components/pop-up/BookingAmendedConfirm.vue'
export { default as PopUpBookingsAcknowledge } from '../../components/pop-up/BookingsAcknowledge.vue'
export { default as PopUpBookingsHistory } from '../../components/pop-up/BookingsHistory.vue'
export { default as PopUpBusinessLicense } from '../../components/pop-up/BusinessLicense.vue'
export { default as PopUpCancelBooking } from '../../components/pop-up/CancelBooking.vue'
export { default as PopUpInsurance } from '../../components/pop-up/Insurance.vue'
export { default as PopUpProvideMissingInformation } from '../../components/pop-up/ProvideMissingInformation.vue'
export { default as PopUpAddExclude } from '../../components/pop-up/addExclude.vue'
export { default as PopUpAddInclude } from '../../components/pop-up/addInclude.vue'
export { default as PopUpBookingLearnMore } from '../../components/pop-up/bookingLearnMore.vue'
export { default as PopUpCompanyInformation } from '../../components/pop-up/companyInformation.vue'
export { default as PopUpConfirmationLearnMore } from '../../components/pop-up/confirmationLearnMore.vue'
export { default as PopUpEnchancedSecurity } from '../../components/pop-up/enchancedSecurity.vue'
export { default as PopUpExamples } from '../../components/pop-up/examples.vue'
export { default as PopUpIntroOffer } from '../../components/pop-up/introOffer.vue'
export { default as PopUpOfficeInformation } from '../../components/pop-up/officeInformation.vue'
export { default as PopUpPerformanceDetails } from '../../components/pop-up/performanceDetails.vue'
export { default as PopUpRefundAlready } from '../../components/pop-up/refundAlready.vue'
export { default as PopUpRegularIntervals } from '../../components/pop-up/regularIntervals.vue'
export { default as PopUpReviewReport } from '../../components/pop-up/reviewReport.vue'
export { default as PopUpScreenResolutionNotice } from '../../components/pop-up/screenResolutionNotice.vue'
export { default as PopUpSelectLanguage } from '../../components/pop-up/selectLanguage.vue'
export { default as PopUpSendEmail } from '../../components/pop-up/sendEmail.vue'
export { default as PopUpSetupPassword } from '../../components/pop-up/setupPassword.vue'
export { default as PopUpTranslateSelect } from '../../components/pop-up/translateSelect.vue'
export { default as PreviewActivity } from '../../components/preview/Activity.vue'
export { default as PreviewActivityIcon } from '../../components/preview/ActivityIcon.vue'
export { default as PreviewAdditionalInfo } from '../../components/preview/AdditionalInfo.vue'
export { default as PreviewAttractionsList } from '../../components/preview/AttractionsList.vue'
export { default as PreviewCancellationPolicy } from '../../components/preview/CancellationPolicy.vue'
export { default as PreviewCovid } from '../../components/preview/Covid.vue'
export { default as PreviewDropOff } from '../../components/preview/DropOff.vue'
export { default as PreviewEndPoint } from '../../components/preview/EndPoint.vue'
export { default as PreviewExcludes } from '../../components/preview/Excludes.vue'
export { default as PreviewFullDescription } from '../../components/preview/FullDescription.vue'
export { default as PreviewHighlights } from '../../components/preview/Highlights.vue'
export { default as PreviewImportantInfo } from '../../components/preview/ImportantInfo.vue'
export { default as PreviewIncluded } from '../../components/preview/Included.vue'
export { default as PreviewMeetingPoint } from '../../components/preview/MeetingPoint.vue'
export { default as PreviewNotSuitable } from '../../components/preview/NotSuitable.vue'
export { default as PreviewPhotoList } from '../../components/preview/PhotoList.vue'
export { default as PreviewPickUp } from '../../components/preview/PickUp.vue'
export { default as PreviewPrice } from '../../components/preview/Price.vue'
export { default as PreviewSlider } from '../../components/preview/Slider.vue'
export { default as PriceAddAttributes } from '../../components/price/AddAttributes.vue'
export { default as PriceAgeForm } from '../../components/price/AgeForm.vue'
export { default as PriceNoMembers } from '../../components/price/NoMembers.vue'
export { default as PriceOption } from '../../components/price/PriceOption.vue'
export { default as PricePricingSchedule } from '../../components/price/PricingSchedule.vue'
export { default as PricePricingTypePage } from '../../components/price/PricingTypePage.vue'
export { default as PriceSchedulesPage } from '../../components/price/SchedulesPage.vue'
export { default as TransitionsFade } from '../../components/transitions/Fade.vue'
export { default as TranslationsEmptyTranslationList } from '../../components/translations/EmptyTranslationList.vue'
export { default as TranslationsStepActivity } from '../../components/translations/StepActivity.vue'
export { default as TranslationsStepBase } from '../../components/translations/StepBase.vue'
export { default as TranslationsStepSchedulesPricing } from '../../components/translations/StepSchedulesPricing.vue'
export { default as TranslationsTranslationList } from '../../components/translations/TranslationList.vue'
export { default as PopUpAccountConfirmEmailCode } from '../../components/pop-up/account/ConfirmEmailCode.vue'
export { default as PopUpAccountConnectReservationSystem } from '../../components/pop-up/account/ConnectReservationSystem.vue'
export { default as PopUpAccountSendEmailCode } from '../../components/pop-up/account/sendEmailCode.vue'
export { default as PopUpCommonInfo } from '../../components/pop-up/common/Info.vue'
export { default as PopUpPriceAddOption } from '../../components/pop-up/price/AddOption.vue'
export { default as PopUpPriceAddSchedule } from '../../components/pop-up/price/AddSchedule.vue'
export { default as PopUpPriceAddSchedulePrices } from '../../components/pop-up/price/AddSchedulePrices.vue'
export { default as PopUpPriceEditOption } from '../../components/pop-up/price/EditOption.vue'
export { default as PopUpPriceEditPrices } from '../../components/pop-up/price/EditPrices.vue'
export { default as PopUpPriceEditSchedule } from '../../components/pop-up/price/EditSchedule.vue'
export { default as PopUpPriceImproveOptions } from '../../components/pop-up/price/ImproveOptions.vue'
export { default as PopUpPricePricingChange } from '../../components/pop-up/price/PricingChange.vue'
export { default as PopUpProductConnectProductToReservationSystem } from '../../components/pop-up/product/ConnectProductToReservationSystem.vue'
export { default as PopUpProductInfo } from '../../components/pop-up/product/productInfo.vue'
export { default as PricePricingChangeFirstPage } from '../../components/price/pricingChange/FirstPage.vue'
export { default as PricePricingChangeGroup } from '../../components/price/pricingChange/Group.vue'
export { default as PricePricingChangePerson } from '../../components/price/pricingChange/Person.vue'
export { default as PricePricingChangeSecondPage } from '../../components/price/pricingChange/SecondPage.vue'
export { default as ProductContentTicketsInformationOnVoucher } from '../../components/productContent/tickets/InformationOnVoucher.vue'

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
