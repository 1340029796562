<template>
  <VDialog
    v-model="modelVisible"
    size="extra-large"
    :title="translations.sendEmailToCustomer.text"
    dialog-classes="pop-up-send-email-title"
  >
    <div class="pop-up-send-email">
      <div class="pop-up-send-email__subtitle">{{ customerWouldPreferLanguage }}</div>
      <div class="pop-up-send-email__content">
        <div class="pop-up-send-email__settings">
          <div class="pop-up-send-email__settings--name">{{ senderName }}</div>
          <div class="pop-up-send-email__types">
            <v-select v-model="type" :items="$options.types" :translates="typesTranslates" />
          </div>
          <div class="pop-up-send-email__upload-pdf" @click="upFile()">
            <base-icon icon-name="Clip" class="pop-up-send-email__upload-pdf--clip" />
            <div
              :class="[
                isFileError ? 'pop-up-send-email__text--error' : '',
                file ? 'has-file' : 'no-file',
                'pop-up-send-email__text',
              ]"
            >
              <div
                v-if="file === null"
                :class="[isFileError ? 'pop-up-send-email__text--error' : '', 'pop-up-send-email__text--title']"
              >
                {{ translations.uploadPdfFile.text }}
              </div>
              <div
                v-if="file === null"
                :class="[isFileError ? 'pop-up-send-email__text--error' : '', 'pop-up-send-email__text--subtitle']"
              >
                {{ translations.filesSize.text }}
              </div>
              <div v-else class="pop-up-send-email__text--name">{{ file.name }}</div>
            </div>
          </div>
        </div>
        <div class="pop-up-send-email__msg">
          <div v-if="isPickupDetails">
            <google-autocomplete
              v-model="$v.pickupAddress.$model"
              :error="$v.pickupAddress.$error"
              :placeholder="translations.enterPickupAddress.text"
              @update:point="updateAddress"
            />
            <p v-if="$v.pickupAddress.$error" class="validation-error">
              {{ translations.pickupAddressError.text }}
            </p>
          </div>
          <VTextarea
            v-model="$v.msg.$model"
            :placeholder="translations.customerMessagePlaceholder.text"
            :error="$v.msg.$error"
            :error-message="translations.customerMessageError.text"
            :class="!isPickupDetails ? 'pop-up-send-email__msg--regular-message' : ''"
            :max-length="400"
            rows="5"
          />
        </div>
      </div>
      <v-btn
        base
        :name="translations.customerMessageSend.text"
        :loading="loading"
        loading-name="Sending"
        class="pop-up-send-email__send"
        @click="sendEmail()"
      />
    </div>
  </VDialog>
</template>

<script>
import { mapState } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import notificationService from "@/services/notification";
import VDialog from "@/components/common/VDialog.vue";
import VBtn from "@/components/controls/VBtn.vue";
import VTextarea from "@/components/controls/VTextarea.vue";
import VSelect from "@/components/controls/VSelect.vue";
import BaseIcon from "@/components/common/BaseIcon.vue";
import GoogleAutocomplete from "@/components/GoogleAutocomplete.vue";

const types = [
  { is_active: true, name: "regularMessage" },
  { is_active: false, name: "pickupDetails" },
];
export default {
  components: { VDialog, VBtn, VTextarea, VSelect, BaseIcon, GoogleAutocomplete },
  model: {
    prop: "visible",
    event: "update:visible",
  },
  types,
  props: {
    visible: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
    bookingId: { type: Number, default: -1 },
    userLanguage: { type: String, default: "en" },
  },
  data: () => ({
    loading: false,
    type: "pickupDetails",
    pickupAddress: "",
    pickupLat: 0,
    pickupLon: 0,
    msg: "",
    file: null,
    isFileError: false,
  }),

  validations() {
    return {
      pickupAddress: { required: requiredIf(() => this.isPickupDetails) },
      msg: { required },
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    modelVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
    isPickupDetails() {
      return this.type === "pickupDetails";
    },
    typesTranslates() {
      return {
        regularMessage: this.translations.messageTypeRegular.text,
        pickupDetails: this.translations.messageTypePickupDetails.text,
      };
    },
    customerWouldPreferLanguage() {
      if (this.userLanguage === "en") {
        return this.translations.customerWouldPreferEnglish.text;
      } else if (this.userLanguage === "es") {
        return this.translations.customerWouldPreferSpanish.text;
      } else {
        return this.translations.customerWouldPreferGerman.text;
      }
    },
    senderName() {
      let name = this.userInfo.company_trading_name ?? "";
      if (name.length > 0) return name;
      name = this.userInfo.company_name ?? "";
      if (name.length > 0) return name;
      name = [this.userInfo.contact_first_name, this.userInfo.contact_last_name].join(" ").trim();
      if (name.length > 0) return name;
      return "Unknown";
    },
  },
  watch: {
    modelVisible() {
      if (this.modelVisible) {
        this.resetModal();
      }
    },
  },

  methods: {
    upFile() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = ".pdf";
      input.onchange = (e) => {
        const [file] = input.files;
        // 10mb
        if (file.size > 1048576 * 10 || !file.type.includes("application/pdf")) {
          this.isFileError = true;
          this.file = null;
        } else {
          this.file = file;
          this.isFileError = false;
        }
      };
      input.click();
    },

    resetModal() {
      this.$v.$reset();
      this.file = null;
      this.pickupAddress = "";
      this.pickupLat = 0;
      this.pickupLon = 0;
      this.msg = "";
      this.type = "pickupDetails";
    },

    updateAddress(address) {
      this.pickupAddress = address.address;
      this.pickupLat = address.lat;
      this.pickupLon = address.lon;
    },

    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const emailData = new FormData();
        emailData.append("message", this.msg);
        emailData.append("message_type", this.type === "pickupDetails" ? "Pickup details" : "Regular message");
        if (this.file != null) emailData.append("my_files", this.file);
        if (this.type === "pickupDetails") {
          emailData.append("pickup_address", this.pickupAddress);
          emailData.append("pickup_lat", this.pickupLat);
          emailData.append("pickup_lon", this.pickupLon);
        }

        this.$axios
          .$post(`/api/v1/dash/order_product/${this.bookingId}/send_message/`, emailData)
          .then(() => {
            notificationService.success({
              text: `Message successfully sent`,
            });
            this.modelVisible = false;
          })
          .catch(() => {
            notificationService.error({ text: "An error occurred while sending the message" });
          });
      }
    },
  },
};
</script>

<style lang="scss">
.pop-up-send-email-title {
  .v-dialog__title {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    width: 900px;
  }
}
.pop-up-send-email {
  &__subtitle {
    font-size: 15px;
    line-height: 18px;
    color: #848997;
    text-align: center;
    margin-bottom: 32px;
  }
  &__content {
    display: flex;
    //width: 530px;
    margin-bottom: 38px;
  }
  &__settings {
    width: 265px;
    margin-right: 21px;
    &--name {
      padding: 17px 16px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      margin-bottom: 17px;
    }
  }
  &__types {
    margin-bottom: 17px;
    .selection__preview {
      box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
      border: 0px;
      height: 55px;
    }

    .selection__expand {
      width: 100%;
      border: none;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
    }
  }
  &__upload-pdf {
    border: 1px dashed #6e6e6e;
    border-radius: 5px;
    padding: 8px 15px;
    cursor: pointer;
    display: flex;
    &--clip {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
  &__text {
    &--name {
      margin-top: 5px;
      margin-left: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 16ch;
    }
    &--title {
      font-size: 18px;
      line-height: 21px;
    }
    &.no-file {
      margin-left: auto;
    }
    &--subtitle {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }
    &--error {
      color: $red;
    }
  }

  &__msg {
    display: flex;
    flex-direction: column;
    gap: 17px 0;
    min-height: 198px;

    .v-input {
      width: 560px;
      margin-top: -10px;
    }

    .v-textarea {
      .v-textarea__wrapper {
        width: 560px;
        height: 125px;

        .v-textarea__textarea {
          border: none;
          box-shadow: 0px 1px 4px rgb(0 0 0 / 15%);
          border-radius: 5px;
        }
      }
    }

    &--regular-message {
      height: 100%;

      .v-textarea__wrapper {
        height: 100% !important;
      }
    }

    .google-autocomplete {
      height: unset;
      padding: 17px 16px;
      border: none;
      border-radius: 5px !important;

      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);

      line-height: 22px;
    }
  }
  &__send {
    margin: auto;
  }
}
</style>
